<template>
    <!-- VIsta 1 => pedidos estado 31 -->
    <reclamos-lista-pedidos :vista="modulo" />
</template>

<script>
import {mapGetters} from 'vuex'

/*
Modulos:
1 pedidos
4 soporte

*/
export default {
    data(){
        return{
            modulo: this.reconocerModulo()
        }
    },
    mounted(){
        this.reconocerModulo()
    },

    methods:{
        reconocerModulo(){
            const route = this.$route.name
            if(route==='admin.soporte.reclamo'){
                return  4// soporte
            }else{
                return 1

            }
        }

    }


}
</script>

