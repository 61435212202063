<template>
    <section class="pt-4 px-3">
        <div class="row mx-0 py-2 align-items-center">
            <div class="col-auto d-middle text-general f-15">
                Rango de análisis
            </div>
            <div class="col-auto" style="width:174px;">
                <el-select v-model="rango" placeholder="Seleccionar" size="small">
                    <el-option
                    v-for="item in rangos"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-auto px-0 mx-2" style="width:248px;">
                <div class="date-picker-edit">
                    <el-date-picker
                    v-model="fecha"
                    class="w-100"
                    size="small"
                    format="dd-MM-yyyy"
                    value-format="yyyy-MM-dd"
                    :disabled="disable_fecha"
                    popper-class="date-picker-edit-popper"
                    type="daterange"
                    unlink-panels
                    start-placeholder="Fecha inicio"
                    end-placeholder="Fecha fin"
                    />
                </div>
            </div>
            <div class="col-auto">
                <div class="btn-general text-white br-8 p-1 f-14 px-3 cr-pointer" @click="proveedoresEstadisticas">
                    Mostrar Información
                </div>
            </div>
            <div class="col-auto d-middle ml-auto">
                <div class="br-5 bg-general2" style="width:18px;height:18px;" />
                <span class="text-general ml-1">
                    Abastecimiento
                </span>
            </div>
            <div class="col-auto d-middle">
                <div class="br-5" style="width:18px;height:18px;background-color:#DFDFDD;" />
                <span class="text-general ml-1">
                    Retiros
                </span>
            </div>
        </div>
        <div class="row mx-0 mt-3 bg-white br-8 py-3 px-3">
            <p class="col-12 text-general f-17 f-600 px-3 mb-4">
                Proveedores más importantes por compras realizadas
            </p>
            <div class="col-auto">
                <div class="bg-fondo text-general text-center p-2 px-5 br-8" style="height:78px;">
                    <p class="f-600 f-22">{{ proveedores.length }}</p>
                    <p class="text-general f-15">
                        Proveedores
                    </p>
                </div>
            </div>
            <div class="col px-0">
                <div class="row mx-0">
                    <div v-for="(importante, i) in importantes" :key="i" class="col-auto mx-3 px-0 text-center mb-3">
                        <img :src="importante.imagen" width="78" class="obj-cover br-8 border-gris" height="78" />
                        <p class="text-general2 tres-puntos f-15 my-2">
                            {{ separadorNumero(importante.compras) }}
                        </p>
                        <el-tooltip placement="bottom" effect="light">
                            <div slot="content">
                                <p class="text-general f-12 text-center pb-2">
                                    {{ importante.nombre }}
                                </p>
                                <div class="row mx-0 mb-2">
                                    <div class="bg-general2 br-5" style="width:14px;height:14px;" />
                                    <span class="text-general2 mx-2 f-12">
                                        {{ agregarSeparadoresNumero(importante.porcentaje_compras_abastecimiento) }}%
                                    </span>
                                    <span class="text-general2 mx-2 f-12">
                                        Abastecimiento
                                    </span>
                                </div>
                                <div class="row mx-0">
                                    <div class="bg-light-f5 br-5" style="width:14px;height:14px;" />
                                    <span class="text-general2 mx-2 f-12">
                                        {{ agregarSeparadoresNumero(importante.porcentaje_compras_retiro) }}%
                                    </span>
                                    <span class="text-general2 mx-2 f-12">
                                        Retiro
                                    </span>
                                </div>
                            </div>
                            <div class="row mx-0 br-5 border" style="height:18px;">
                                <div class="h-100 bg-general2" :class="`${importante.porcentaje_compras_retiro == 0 ? 'br-5' : 'br-l-5'}`" :style="`width:${porcentajesBordes(importante.porcentaje_compras_abastecimiento)}%`" />
                                <div class="h-100 bg-df" :class="`${importante.porcentaje_compras_abastecimiento == 0 ? 'br-5' : 'br-r-5'}`" :style="`width:${porcentajesBordes(importante.porcentaje_compras_retiro)}%`" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <div v-if="proveedores.length > 7" class="col-auto pt-4">
                <div class="ver-mas text-white br-8 bg-general3 cr-pointer d-middle-center mt-2" @click="verMas()">
                    Ver más
                </div>
            </div>
        </div>
        <div class="row mx-0 py-3" />
        <div class="row mx-0 bg-white br-8 py-3 px-3">
            <div class="col-12 text-general f-17 f-600 mb-4 px-3">
                Últimos proveedores creados y número de CEDIS asociados
            </div>
            <div class="col-auto px-4" />
            <el-tooltip v-for="(ultimo,u) in ultimos" :key="u" placement="bottom" :content="ultimo.nombre" effect="light">
                <div class="col-auto">
                    <img :src="ultimo.imagen" width="78" class="obj-cover br-8 border-gris" height="78" />
                    <p class="text-general2 f-14 text-center f-600 my-2">
                        {{ agregarSeparadoresNumero(ultimo.cedis) }}
                    </p>
                </div>
            </el-tooltip>
        </div>
        <div class="row mx-0 py-3" />
        <div class="row mx-0 bg-white br-8 px-3 py-3">
            <div class="col-12 text-general f-17 f-600 mb-4 px-3">
                Proveedores por defecto por cantidad de productos (Modalidad abastecimiento)
            </div>
            <div class="col-auto px-4" />
            <el-tooltip v-for="(data,d) in cedisProductosAbastecimiento" :key="d" placement="bottom" :content="data.nombre" effect="light">
                <div class="col-auto cr-pointer" @click="modalProductosProveedor(data, 1)">
                    <img :src="data.imagen" width="78" class="obj-cover br-8" height="78" />
                    <p class="text-general2 f-14 text-center f-600 my-2">
                        {{ agregarSeparadoresNumero(data.cantidad) }}
                    </p>
                </div>
            </el-tooltip>
        </div>
        <div class="row mx-0 py-3" />
        <div class="row mx-0 bg-white br-8 px-3 py-3">
            <div class="col-12 text-general f-17 f-600 px-3 mb-4">
                Proveedores por defecto por cantidad de productos (Modalidad retiro)
            </div>
            <div class="col-auto px-4" />
            <el-tooltip v-for="(data,d) in cedisProductosRetiro" :key="d" placement="bottom" :content="data.nombre" effect="light">
                <div class="col-auto cr-pointer" @click="modalProductosProveedor(data, 0)">
                    <img :src="data.imagen" width="78" class="obj-cover border-gris br-8" height="78" />
                    <p class="text-general2 f-14 text-center f-600 my-2">
                        {{ agregarSeparadoresNumero(data.cantidad) }}
                    </p>
                </div>
            </el-tooltip>
        </div>
        <!-- Partials -->
        <modal-proveedores-importantes ref="modalProveedoresImportantes" />
        <modal-proveedores-productos ref="modalProveedoresProductos" />
    </section>
</template>

<script>
import Proveedor from "~/services/proveedores/proveedores_estadisticas";
import { mapGetters } from 'vuex'
import moment from 'moment';
import proveedores from '~/router/proveedores/proveedores';
export default {
    components: {
        modalProveedoresImportantes: () => import('./partials/modalProveedoresImportantes.vue'),
        modalProveedoresProductos: () => import('./partials/modalProveedoresProductos.vue'),
    },
    data(){
        return {
            url_proveedor: 'https://pintuco.com.co/wp-content/uploads/2020/01/pintar-fachadas-tienda-d1-pintuco-imgppal.jpg',
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Última semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Último mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Último año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 5,
            fecha: [moment().subtract(1, 'month').toDate(),new Date()],
            disable_fecha: true,
            proveedores: [],
            importantes: [],
            ultimos: [],
            cedisProductosAbastecimiento: [],
            cedisProductosRetiro: [],
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
            id_cedis:'cedis/id_cedis',
        }),
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;

            default:
                break;
            }
        },
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
        id_cedis(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                if(this.id_moneda == null || this.id_cedis == null){
                    setTimeout(async() => {
                        await this.proveedoresEstadisticas()
                    }, 1000);
                }else{
                    await this.proveedoresEstadisticas()
                }
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        async proveedoresEstadisticas(){
            try {
                let params =  {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis,
                }
                const {data} = await Proveedor.proveedoresEstadisticas(params)
                this.proveedores = data.importantes
                this.importantes = data.importantes.slice(0,7)
                this.ultimos = data.ultimos
                this.cedisProductosAbastecimiento = data.cedisProductosAbastecimiento
                this.cedisProductosRetiro = data.cedisProductosRetiro
            } catch (e){
                this.error_catch(e)
            }
        },
        verMas(){
            let fecha_inicio =  moment(this.fecha[0]).format('Y-MM-DD')
            let fecha_fin =  moment(this.fecha[1]).format('Y-MM-DD')
            let fecha = `${this.formatearFecha(fecha_inicio,'D MMM YYYY')} - ${this.formatearFecha(fecha_fin,'D MMM YYYY')}`
            this.$refs.modalProveedoresImportantes.toggle(this.proveedores, fecha);
        },
        async modalProductosProveedor(proveedor, tipo){
            try {
                let params =  {
                    id_proveedor: proveedor.id,
                    tipo,
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis,
                }
                const {data} = await Proveedor.modalProductosProveedor(params)
                console.log(data);

                this.$refs.modalProveedoresProductos.toggle(data.productosProveedor, proveedor);
            } catch (e){
                this.error_catch(e)
            }

        }
    }
}
</script>
<style lang="scss" scoped>
.ver-mas{
    width: 78px;
    height: 78px;
}
.bg-df{
    background-color: #DFDFDD !important;
}
</style>
