<template>
    <section>
        <tabs :tabs="rutas" class="border-bottom" />
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 125px);">
            <router-view />
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            rutas: [
                {
                    titulo: 'Lista de Proveedores',
                    ruta: 'admin.proveedores.lista'
                },
                {
                    titulo: 'Estadísticas',
                    ruta: 'admin.proveedores.estadisticas',
                    can: 'tab_proveedores_estadisticas'
                },
                {
                    titulo: 'Compras',
                    ruta: 'admin.proveedores.compras',
                    can: 'tab_proveedores_estadisticas'
                }
            ]
        }
    },
}
</script>
