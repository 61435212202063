import axios from "axios";

const API = "proveedores/compras";

const ENDPOINTS = {
    getInfoSelects(){
        return axios.get(`${API}/info`)
    },
    getComprasProveedores(params){
        return axios.get(`${API}/listado`,{params})
    },
    getProveedoresCedis(id){
        return axios.get(`${API}/${id}/proveedores`)
    },
    putInventarioProveedor(id, form){
        return axios.put(`${API}/${id}/inventario-proveedor`,form)
    },
    putProveedorFavorito(id,form){
        return axios.put(`${API}/${id}/proveedor-favorito`,form)
    }
};

export default ENDPOINTS;