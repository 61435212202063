<template>
    <section class="custom-scroll overflow-auto" style="height:calc(100vh - 94px)">
        <div class="row mx-0 bg-white br-8 p-3 mb-4">
            <div class="col-xl col-lg col-md-12 col-sm-12 col-12 px-0">
                <p class="text-general mb-3 f-600 f-18">
                    Recompensas diarias
                </p>
                <p class="text-dk-blue mb-5">
                    Hoy tienes la oportunidad de girar la ruleta de la fortuna o llevarte la recompensa del día
                </p>
                <div class="row mx-0">
                    <div class="col-xl-6 col-lg col-md col-sm-12 col-12 pl-0">
                        <div class="btn-gr-blue">
                            <img src="/img/gaming/i_ruleta_3D.png" class="position-absolute" />
                            <div class="col" />
                            <div class="col text-center">
                                <p class="font-omnes text-center f-16 f-600 text-white lh-19">
                                    RULETA DE <br /> LA SUERTE
                                </p>
                                <div class="row mx-0 justify-center mt-2">
                                    <div class="btn-general-gr font-omnes px-4" @click="girarRuleta">
                                        GIRAR
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg col-md col-sm-12 col-12 pl-xl-3 pl-lg-3 pl-md-3 pl-sm-0 pl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-5 mt-5">
                        <div class="btn-gr-purple">
                            <img src="/img/gaming/i_calendario.png" class="position-absolute" width="80" height="80" />
                            <div class="col-5" />
                            <div class="col text-center pl-0">
                                <div class="row mx-0 mb-3 justify-center">
                                    <div class="space-coins mr-2 position-relative">
                                        <img src="/img/gaming/i_moneda.svg" height="35" />
                                        <span class="col px-2 tres-puntos text-white f-600 f-15">+46</span>
                                    </div>
                                    <div class="space-coins ml-2 position-relative">
                                        <img src="/img/gaming/i_gema.svg" height="35" />
                                        <span class="col px-2 tres-puntos text-white f-600 f-15">+46</span>
                                    </div>
                                </div>
                                <div class="row mx-0 justify-center mt-2">
                                    <div class="btn-red-gr font-omnes px-4">
                                        RECOGER
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-xl-0 mt-lg-0 mt-md-3 mt-sm-3 mt-3">
                <div class="d-flex mb-3">
                    <span class="text-general f-600 f-18">
                        Historial
                    </span>
                    <span class="text-general-red cr-pointer f-600 f-18 cr-pointer ml-auto" @click="historial">
                        Ver más
                    </span>
                </div>
                <div v-for="(hs, h) in historial_recompensas" :key="h" class="btn-gr-green px-3 mb-3">
                    <div class="col-auto mr-3 px-2 f-24 font-omnes text-white">
                        DÍA 6
                    </div>
                    <div class="col d-flex">
                        <template v-if="hs.tipo == 1">
                            <div class="space-coin text-center">
                                <img src="/img/gaming/i_moneda.svg" />
                                <span class="text-white f-600 font-omnes">
                                    + 45
                                </span>
                            </div>
                        </template>
                        <template v-if="hs.tipo == 2">
                            <img src="https://http2.mlstatic.com/D_NQ_NP_703051-MCO44296939957_122020-W.jpg" class="br-4 obj-cover" width="40" height="40" />
                            <div class="col-auto font-omnes f-17 text-white f-400">
                                Canguro NIKE
                            </div>
                        </template>
                        <template v-if="hs.tipo == 3">
                            <img src="/img/gaming/i_triste.png" class="br-4 obj-cover" width="50" height="50" />
                            <div class="col-auto pl-2 font-omnes f-17 text-white f-400 lh-19">
                                Sigue <br />
                                intentando
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 bg-white br-8 p-3 mb-4">
            <div class="col px-0">
                <p class="text-general mb-3 f-600 f-18">
                    Recompensas diarias (últimas recompensas obtenidas)
                </p>
                <p class="text-dk-blue mb-5">
                    Regresa mañana y obtén tu recompensa diaria
                </p>
                <div class="row mx-0 mt-3">
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 pl-0">
                        <div class="btn-gr-purple">
                            <img src="/img/gaming/i_group_regalo.png" class="position-absolute" width="80" height="80" />
                            <div class="col-5" />
                            <div class="col-5 ml-auto text-center pl-0">
                                <div class="row mx-0 mb-3 justify-center">
                                    <div class="space-coins mr-2 position-relative">
                                        <img src="/img/gaming/i_moneda.svg" height="35" />
                                        <span class="col px-2 tres-puntos text-white f-600 f-18">+46</span>
                                    </div>
                                </div>
                                <div class="row mx-0 justify-center">
                                    <div class="space-coins ml-2 position-relative">
                                        <img src="/img/gaming/i_gema.svg" height="35" />
                                        <span class="col px-2 tres-puntos text-white f-600 f-18">+46</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 pl-0 pl-xl-3 pl-lg-3 pl-md-3 pl-sm-0 pl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-5 mt-5">
                        <div class="btn-gr-purple">
                            <img src="/img/gaming/i_cupon.png" class="position-absolute" width="80" height="80" />
                            <div class="col-5" />
                            <div class="col text-center pl-0">
                                <p class="text-center font-omnes text-white">
                                    Cupón
                                </p>
                                <div class="row mx-0 mb-3 justify-center">
                                    <div class="space-cupon mr-2 position-relative">
                                        <span class="col px-2 tres-puntos text-white f-600 f-18">$ 50.000</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 mt-5">
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 pl-0">
                        <div class="btn-gr-purple">
                            <img src="/img/gaming/i_triste.png" class="position-absolute" width="80" height="80" />
                            <div class="col-5" />
                            <div class="col text-center pl-0 font-omnes text-white lh-19">
                                Lo sentimos <br />
                                Sigue intentando
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 pl-0 pl-xl-3 pl-lg-3 pl-md-3 pl-sm-0 pl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-5 mt-5">
                        <div class="btn-gr-purple">
                            <img src="https://http2.mlstatic.com/D_NQ_NP_703051-MCO44296939957_122020-W.jpg" class="position-absolute obj-cover br-12 shadow-29" width="106" height="106" />
                            <div class="col-5" />
                            <div class="col text-center pl-0">
                                <p class="text-center font-omnes text-white f-18 lh-19">
                                    Canguro <br /> NIKE 2022
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-xl-0 mt-lg-0 mt-md-3 mt-sm-3 mt-3">
                <div class="d-flex mb-3">
                    <span class="text-general f-600 f-18">
                        Historial
                    </span>
                    <span class="text-general-red f-600 f-18 cr-pointer ml-auto" @click="historial">
                        Ver más
                    </span>
                </div>
                <div v-for="(hs, h) in historial_recompensas" :key="h" class="btn-gr-green px-3 mb-3">
                    <div class="col-auto mr-3 px-2 f-24 font-omnes text-white">
                        DÍA 6
                    </div>
                    <div class="col pl-0 d-flex">
                        <template v-if="hs.tipo == 1">
                            <div class="space-cupon ml-3 text-center">
                                <img src="/img/gaming/i_gema.svg" />
                                <span class="text-white px-3 pl-4 text-right f-600 font-omnes">
                                    $ 50.000
                                </span>
                            </div>
                        </template>
                        <template v-if="hs.tipo == 2">
                            <img src="/img/gaming/i_triste.png" class="br-4 obj-cover" width="50" height="50" />
                            <div class="col-auto pl-2 font-omnes f-17 text-white f-400">
                                Sigue <br />
                                intenado
                            </div>
                        </template>
                        <template v-if="hs.tipo == 3">
                            <div class="space-cupon ml-3 text-center">
                                <img src="/img/gaming/i_cupon.png" />
                                <span class="text-white px-3 pl-4 text-right f-600 font-omnes">
                                    $ 50.000
                                </span>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <recompensas-logros />
        <misiones />
        <!-- Partials -->
        <modal-girar-ruleta ref="modalGirarRuleta" />
        <modal-historial-recompensas ref="modalHistorialRecompensas" />
    </section>
</template>

<script>
export default {
    components: {
        recompensasLogros: () => import('./components/recompensasLogros'),
        misiones: () => import('./components/misiones'),
        modalGirarRuleta: () => import('./partials/modalGirarRuleta'),
        modalHistorialRecompensas: () => import('./partials/modalRecompensas')
    },
    data(){
        return {
            historial_recompensas: [
                {
                    dia: 'DÍA 7',
                    tipo: 1
                },
                {
                    dia: 'DÍA 6',
                    tipo: 2
                },
                {
                    dia: 'DÍA 5',
                    tipo: 3
                }
            ],
        }
    },
    methods: {
        girarRuleta(){
            this.$refs.modalGirarRuleta.toggle()
        },
        historial(){
            this.$refs.modalHistorialRecompensas.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>

.btn-general-gr{
    height: 36px;
    background-image: linear-gradient(to bottom, #CDB5FF, #612AD5);
    border-radius: 12px;
    color: #ffffff;
    border: none;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    &:hover{
        box-shadow: 0 1px 4px #00000063;
    }
    &:focus{
        outline: none;
    }
}


.btn-red-gr{
    height: 36px;
    background-image: linear-gradient(to bottom, #FFAAAA, #F27777, #F86464, #F23E3E);
    border-radius: 12px;
    color: #ffffff;
    border: none;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    &:hover{
        box-shadow: 0 1px 4px #00000063;
    }
    &:focus{
        outline: none;
    }
}


.btn-gr-blue{
    height: 98px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    position: relative;
    background-image: linear-gradient(to right, #def8ff, #80E9FF, #4AC8E8);
    img{
        top: -36px;
        left: 25px;
        height: 130px;
        width: 130px;
    }
}

.btn-gr-purple{
    height: 98px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    position: relative;
    background-image: linear-gradient(to bottom, #F9C7FF, #9F4BFF, #612AD5);
    .space-coins{
        width: 79px;
        height: 24px;
        margin-right: 10px;
        border-radius: 8px;
        display: flex;
        background-image: linear-gradient(to right, #402180, #865BE4);
        img{
            top:-6px;
            left: -15px;
            position: absolute;
        }
    }
    .space-cupon{
        width: 120px;
        height: 24px;
        margin-right: 10px;
        border-radius: 8px;
        display: flex;
        background-image: linear-gradient(to right, #402180, #865BE4);
    }
    img.position-absolute{
        top: -47px;
        left: 25px;
        height: 130px;
        width: 130px;
    }
}



.btn-gr-green{
    height: 54px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    background: radial-gradient(circle at 162%, #B1FFE4,#80FFB7, #0DEC81);
    /* background-image: linear-gradient(to right, #0DEC81, #80FFB7, #B1FFE4); */
    .space-coin{
        max-width: 130px;
        height: 24px;
        border-radius: 4px;
        color: #FFFFFF;
        position: relative;
        background-color: #00C764;
        img{
            height: 33px;
            position: absolute;
            left: -15px;
            top: -5px;
        }
    }
    .space-cupon{
        max-width: 130px;
        height: 24px;
        border-radius: 4px;
        color: #FFFFFF;
        position: relative;
        background-color: #00C764;
        img{
            height: 33px;
            position: absolute;
            left: -15px;
            top: -5px;
        }
    }
}

</style>