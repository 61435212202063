<template>
    <div style="height:calc(100vh - 71px);">
        <div class="row mx-0 border-bottom">
            <tabs :tabs="tabs" />
        </div>
        <router-view />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    metaInfo: () =>({ title: 'Reclamos'}),
    data(){
        return {
            tabsReales: {
                tendero: [
                    { titulo: 'Reclamo', ruta: 'tendero.reclamos.reclamos', num: 0 },
                    { titulo: 'Finalizados', ruta: 'tendero.reclamos.terminados', num: 0 },
                ],
                'tendero-vip': [
                    { titulo: 'Reclamo', ruta: 'tendero-vip.reclamos.reclamos', num: 0 },
                    { titulo: 'Finalizados', ruta: 'tendero-vip.reclamos.terminados', num: 0},
                ]
            }
        }
    },
    computed: {
        tabs(){
            return this.tabsReales[this.$usuario.rol_nombre]
        },
        ...mapGetters({
            cant_reclamos: 'reclamos/reclamos/cant_reclamos',
            cant_reclamos_fin: 'reclamos/reclamos/cant_reclamos_fin'
        }),
    },
    mounted(){
        this.listarCantidades()
    },
    methods:{
        async listarCantidades(){
            // await this.listarPedidos() // lista la vista 2 para q cargue cantidad finalizados
            this.tabsReales[this.$usuario.rol_nombre][0].num = this.cant_reclamos
            this.tabsReales[this.$usuario.rol_nombre][1].num = this.cant_reclamos_fin

        },
        async listarPedidos(){
            try {
                this.loading = true
                var myVista = 2
                console.log(this.$route.name,'this.$route.name');
                if(this.$route.name==='tendero.reclamos.terminados' ){
                    myVista = 3
                }
                if(this.$route.name==='tendero.reclamos.reclamos' ){
                    myVista = 3 // la 3 para q recarge los finalizados
                }

                const enviar = {
                    vista: myVista
                }
                await this.$store.dispatch('reclamos/reclamos/action_listar_pedidos', enviar)
                this.loading = false
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },

    }
}
</script>
