<template>
    <section>
        <div class="row mx-0 pr-3 mb-3 align-items-center">
            <router-link :to="{name: 'admin.proveedores.lista'}">
                <div class="bg-general3 d-middle-center rounded-circle text-white mx-2" style="width:20px;height:20px;">
                    <i class="icon-left f-10" />
                </div>
            </router-link>
            <img :src="proveedor.imagen" height="36" width="36" class="obj-cover br-5 border-gris" />
            <div class="col-auto text-general f-18">
                {{ proveedor.nombre }}
            </div>
            <div class="col-auto px-1 ml-auto">
                <div class="bg-general3 text-white br-20 px-2 py-1 f-14 cr-pointer shadow" @click="cambioEstadoProveedor">
                    {{ proveedor.estado ? 'Activo' : 'Inactivo' }}
                </div>
            </div>
            <div v-if="$can('botones_proveedores_gestionar')" class="btn-action cr-pointer border mx-2 text-general" @click="editarProveedor">
                <i class="icon-pencil-outline f-16" />
            </div>
            <div v-if="$can('boton_proveedores_eliminar')" class="btn-action cr-pointer border mx-2 text-general" @click="eliminarProveedor">
                <i class="icon-delete-outline f-16" />
            </div>
        </div>
        <tabs :tabs="rutas" class="border-bottom" />
        <div class="custom-scroll overflow-auto" :style="`height:${$route.name == rutas[0].ruta || $route.name == rutas[1].ruta ? 'auto' : 'calc(100vh - 193px)'}`">
            <router-view />
        </div>
        <!-- Partials -->
        <modal-editar-proveedor ref="modalEditarProveedor" />
        <modal-eliminar ref="modalEliminarProveedor" titulo="Eliminar Proveedor" icon="trash" @eliminar="deleteProveedor">
            <div class="row mx-0">
                <div class="col-12 text-general f-15 text-center">
                    ¿Desea eliminar este proveedor? <br /> Perderá toda la información
                </div>
            </div>
        </modal-eliminar>
        <modal ref="modalInactivaroActivar" icon="entrega" :titulo="`${!proveedor.estado ? 'Activar' : 'Inactivar'}`" @guardar="cambiarEstadoProveedor">
            <div class="row mx-0 my-3">
                <div class="col-12 text-general f-15 text-center">
                    ¿Desea {{ !proveedor.estado ? 'activar' : 'inactivar' }} a este proveedor?
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import Proveedor from "~/services/proveedores/proveedores";
import { mapGetters } from 'vuex'
export default {
    components: {
        modalEditarProveedor: () => import('../partials/modalEditarProveedor')
    },
    data(){
        return {
            rutas: [
                {
                    titulo: 'Información',
                    ruta: 'admin.proveedores.ver.informacion-proveedor'
                },
                {
                    titulo: 'Productos',
                    ruta: 'admin.proveedores.ver.productos',
                    can: 'tab_proveedores_productos'
                },
                {
                    titulo: 'Estadisticas',
                    ruta: 'admin.proveedores.ver.estadisticas',
                    can: 'tab_proveedores_estadisticas_perfil'
                },
                {
                    titulo: 'Comentarios',
                    ruta: 'admin.proveedores.ver.comentarios',
                    can: 'tab_proveedores_estadisticas_perfil'
                }
            ],
            url_image: 'https://pintuco.com.co/wp-content/uploads/2020/01/pintar-fachadas-tienda-d1-pintuco-imgppal.jpg',
            estadoProveedor: false,
        }
    },
    computed: {
        ...mapGetters({
            proveedor:'proveedores/proveedores/proveedor',
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
    },
    watch: {
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
        id_cedis(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        }
    },
    created(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda === null){
                    setTimeout(async() => {
                        await this.getProveedor()
                    }, 1000);
                }else{
                    await this.getProveedor()
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async getProveedor(){
            try {
                let params =  {
                    id: this.$route.params.id_proveedor,
                    id_cedis: this.id_cedis,
                    id_moneda: this.id_moneda,
                }
                const {data} = await Proveedor.getProveedor(params)
                this.$store.commit('proveedores/proveedores/setProveedor', data.proveedor)
            } catch (e){
                this.error_catch(e)
            }
        },
        editarProveedor(){
            this.$refs.modalEditarProveedor.toggle(this.proveedor);
        },
        eliminarProveedor(){
            this.$refs.modalEliminarProveedor.toggle();
        },
        async deleteProveedor(){
            try {
                const {data} = await Proveedor.deleteProveedor(this.$route.params.id_proveedor)
                this.notificacion('', data.mensaje, 'success')
                this.$router.push({ name: 'admin.proveedores.lista'})
                this.$refs.modalEliminarProveedor.toggle();
            } catch (e){
                this.error_catch(e)
            }
        },
        async cambiarEstadoProveedor(){
            try {
                const {data} = await Proveedor.cambiarEstadoProveedor(this.$route.params.id_proveedor)
                this.notificacion('', data.mensaje, 'success')
                this.$refs.modalInactivaroActivar.toggle();
                this.getProveedor()

            } catch (e){
                this.error_catch(e)
            }
        },
        cambioEstadoProveedor(){
            if (!this.$can('botones_proveedores_gestionar')) return
            this.$refs.modalInactivaroActivar.toggle();
        }
    }
}
</script>

<style lang="scss" scoped>
</style>

