<template>
    <section>
        <div class="row mx-0 mt-3">
            <div class="col-12">
                <tabla-general :data="proveedores" :columnas="dataColumnas" alto="calc(100vh - 284px)" :activar-seleccion="true" @seleccion="push">
                    <template slot="cabecera-izquierda">
                        <div class="col d-flex pr-0 py-2">
                            <div class="col-auto text-general f-18 f-500">
                                Lista proveedores
                            </div>
                            <div class="col-auto px-0 ml-auto">
                                <div v-if="$can('botones_proveedores_gestionar')" class="bg-general text-white f-14 br-8 py-1 px-4 shadow cr-pointer" @click="CrearProveedor()">
                                    Crear proveedor
                                </div>
                            </div>
                        </div>
                    </template>
                    <template slot="adicionales-derecha">
                        <el-table-column
                        :default-sort="{prop: 'compras', order: 'descending'}"
                        label="Compras último mes"
                        prop="compras"
                        class-name="text-general f-16 text-center"
                        width="250"
                        sortable
                        >
                            <template slot-scope="scope">
                                <p class="f-16 text-center">
                                    {{ separadorNumero(scope.row.compras) }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Estado"
                        class-name="text-general f-16 text-center"
                        width="80"
                        >
                            <template slot-scope="scope">
                                <p class="f-16" :class="scope.row.estado == 0 ? 'text-general-red' : ''">
                                    {{ scope.row.estado == 0 ? 'Inactivo' : 'Activo' }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label=""
                        class-name="text-general f-16 text-center"
                        width="200"
                        >
                            <template slot-scope="scope">
                                <div v-if="scope.row.nuevo" class="row mx-0 text-general f-16">
                                    <el-tooltip placement="bottom" content="Creado en el último mes" effect="light">
                                        <div class="bg-green text-white px-2 br-20">
                                            Nuevo
                                        </div>
                                    </el-tooltip>
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <!-- Partials -->
        <modal-crear-proveedor ref="modalCrearProveedor" />
    </section>
</template>

<script>
import Proveedor from "~/services/proveedores/proveedores";
import { mapGetters } from 'vuex'
export default {
    components: {
        modalCrearProveedor: () => import ('./partials/modalCrearProveedor')
    },
    data(){
        return {
            proveedores: [],
            dataColumnas: [
                { valor: 'cedis', titulo:"Cedis", class: 'text-general f-16 text-center' },
                { valor: 'nombre', titulo:"Proveedor", class: 'text-general f-16 text-center' },
                { valor: 'ciudad', titulo:"Ciudad", class: 'text-general f-16 text-center' },
                { valor: 'productos', titulo:"Productos", class: 'text-general f-16 text-center' },
            ]
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
    },
    watch: {
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
        id_cedis(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda === null){
                    setTimeout(async() => {
                        await this.listarProveedores()
                    }, 1000);
                }else{
                    await this.listarProveedores()
                }
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        CrearProveedor(){
            this.$refs.modalCrearProveedor.toggle();
        },
        async listarProveedores(){
            try {
                let params =  {
                    id_cedis: this.id_cedis,
                    id_moneda: this.id_moneda,
                }
                const {data} = await Proveedor.listarProveedores(params)
                this.proveedores = data.proveedores
            } catch (e){
                this.error_catch(e)
            }
        },
        push(row){
            this.$router.push({ name: 'admin.proveedores.ver', params :{ id_proveedor: row.id } })
        },

    }
}
</script>
