<template>
    <section class="mb-3">
        <div class="row mx-0 px-5 mt-4 bg-white br-8 py-3">
            <div class="col-12 mt-3 text-general f-18 f-600">
                Compras al proveedor en las últimas 16 semanas
            </div>
            <div class="col-12 mt-2">
                <echart :options="compras_proveedor" />
            </div>
        </div>
        
        <div class="row mx-0 ml-5 pl-3 mt-5 mb-4 align-items-center">
            <div class="col-auto d-middle text-general f-15">
                Rango de análisis
            </div>
            <div class="col-auto" style="width:174px;">
                <el-select v-model="rango" placeholder="Seleccionar" size="small">
                    <el-option
                    v-for="item in rangos"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-auto px-0 mx-2" style="width:248px;">
                <div class="date-picker-edit">
                    <el-date-picker
                    v-model="fecha"
                    class="w-100"
                    size="small"
                    format="dd-MM-yyyy"
                    value-format="yyyy-MM-dd"
                    :disabled="disable_fecha"
                    popper-class="date-picker-edit-popper"
                    type="daterange"
                    unlink-panels
                    start-placeholder="Fecha inicio"
                    end-placeholder="Fecha fin"
                    />
                </div>
            </div>
            <div class="col-auto">
                <div class="bg-general text-white br-8 p-1 f-15 px-3 cr-pointer" @click="proveedorSemanasInforme">
                    Mostrar Información
                </div>
            </div>
        </div>
        <div class="row mx-0 px-5 mt-4 bg-white br-8 pb-4 mb-3">
            <p class="col-12 text-general f-18 f-600 my-3">
                Compras al proveedor
            </p>
            <div class="col-12 px-0">
                <div class="row mx-0">
                    <div class="col-4">
                        <div class="bg-light-f5 shadow text-general px-3 py-3 br-8 d-middle-center">
                            Compras por retiro
                            <b class="mx-2">
                                {{ separadorNumero(totalRetiros) }}
                            </b>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="text-general br-8 px-3 py-3 shadow" style="background-color:#DFDFDD;">
                            {{ agregarSeparadoresNumero(porcentajeRetiros) }} %
                        </div>
                    </div>
                    <div class="col-auto text-general d-middle-center">
                        -
                    </div>
                    <div class="col-auto">
                        <div class="bg-general2 text-white br-8 px-3 py-3 shadow">
                            {{ agregarSeparadoresNumero(porcentajeAbastecimiento) }} %
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="bg-light-f5 shadow text-general px-3 py-3 br-8 d-middle-center">
                            Compras por Abastecimiento
                            <b class="mx-2">
                                {{ separadorNumero(totalAbastecimiento) }}
                            </b>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 px-0 py-4" />
            <div class="col-12 px-0">
                <div class="row mx-0">
                    <div class="col-4">
                        <div class="bg-light-f5 shadow text-general px-3 py-3 br-8 d-middle-center">
                            Compras a este Proveedor
                            <b class="mx-2">
                                {{ separadorNumero(total) }}
                            </b>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="text-general br-8 px-3 py-3 shadow" style="background-color:#DFDFDD;">
                            {{ agregarSeparadoresNumero(porcentajeTotal) }} %
                        </div>
                    </div>
                    <div class="col-auto text-general d-middle-center">
                        -
                    </div>
                    <div class="col-auto">
                        <div class="bg-general2 text-white br-8 px-3 py-3 shadow">
                            {{ agregarSeparadoresNumero(porcentajeTotalOtros) }} %
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="bg-light-f5 shadow text-general px-3 py-3 br-8 d-middle-center">
                            Compras a los otros Proveedores
                            <b class="mx-2">
                                {{ separadorNumero(totalOtros) }}
                            </b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Vue from 'vue'
import echarts from 'echarts'

let colorGeneral2 = getComputedStyle(document.documentElement).getPropertyValue('--color-general2')
import Proveedor from "~/services/proveedores/proveedores_estadisticas";
import { mapGetters } from 'vuex'
import moment from 'moment';
export default {
    data(){
        let self = this
        return{
            compras_proveedor: {
                legend: {
                    top: 25,
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        fontSize: 12,
                        overflow: 'truncate',
                        color: '#707070',
                    },
                    data: ['Abastecimiento', 'Retiro']
                },
                tooltip: {
                    trigger: 'axis',
                    show: true,
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        // var value = obj.value;
                        // console.log(obj);
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.semana}</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2">${self.separadorNumero(obj[0].data.abastecimiento)} ${obj[0].seriesName}</span>` + '<br>'
                        +  `<i class="icon-blank f-18" style="color:${obj[1].color}" /><span class="text-general2">${self.separadorNumero(obj[1].data.retiros)} ${obj[1].seriesName}</span>` + '<br>'
                        + '</div>'
                    }
                },
                grid: {
                    left: '3%',
                    top: '20%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        // show: false
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    }
                ],
                color: [
                    '#707070',
                    '#DBDBDB',
                ],
                dataset:{
                    dimensions:['semana', 'abastecimiento','retiros'],
                    source: []
                },
                series: [
                    {
                        name: 'Abastecimiento',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: 'Retiro',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                ]
            },
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Última semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Último mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Último año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 5,
            fecha: [moment().subtract(1, 'month').toDate(),new Date()],
            disable_fecha: true,
            totalAbastecimiento: 0,
            totalRetiros: 0,
            porcentajeAbastecimiento: 0,
            porcentajeRetiros: 0,
            total: 0,
            totalOtros: 0,
            porcentajeTotal: 0,
            porcentajeTotalOtros: 0,
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
        }),
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;

            default:
                break;
            }
        },
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                if(this.id_moneda === null){
                    setTimeout(async() => {
                        await this.proveedorSemanas()
                        await this.proveedorSemanasInforme()
                    }, 1000);
                }else{
                    await this.proveedorSemanas()
                    await this.proveedorSemanasInforme()
                }
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        async proveedorSemanas(){
            try {
                let params =  {
                    id: this.$route.params.id_proveedor,
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_moneda: this.id_moneda,
                }
                const {data} = await Proveedor.proveedorSemanas(params)
                this.compras_proveedor.dataset.source = data.semanas
            } catch (e){
                this.error_catch(e)
            }
        },
        async proveedorSemanasInforme(){
            try {
                let params =  {
                    id: this.$route.params.id_proveedor,
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_moneda: this.id_moneda,
                }
                const {data} = await Proveedor.proveedorSemanasInforme(params)
                this.totalAbastecimiento = data.totalAbastecimiento
                this.totalRetiros = data.totalRetiros
                this.porcentajeAbastecimiento = data.porcentajeAbastecimiento
                this.porcentajeRetiros = data.porcentajeRetiros
                this.total = data.total
                this.totalOtros = data.totalOtros
                this.porcentajeTotal = data.porcentajeTotal
                this.porcentajeTotalOtros = data.porcentajeTotalOtros
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style>

</style>
