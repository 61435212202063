<template>
    <!-- solo para la vista (tab) reclamos terminados -->
    <reclamos-lista-pedidos :vista="modulo" />
</template>

<script>

export default {
    data(){
        return {
            modulo: this.reconocerModulo()

        }
    },
    methods:{
        reconocerModulo(){
            if(this.$route.name === 'admin.soporte.reclamos-terminados'){
                return 5
            }else{
                return 6
            }
        }

    }
}
</script>

