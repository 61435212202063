<template>
    <section class="pt-3">
        <tabla-general :data="tableData" :activar-seleccion="true" alto="calc(100vh - 293px)" @seleccion="verProducto">
            <template slot="cabecera-izquierda">
                <div class="col pr-0 py-2">
                    <div class="row mx-0">
                        <div class="col-auto f-600 text-general">
                            Productos del proveedor
                        </div>
                        <div class="col-auto px-0 d-middle ml-auto">
                            <div class="bg-general2 br-4" style="width:18px;height:18px;" />
                            <span class="text-general f-15 mx-3">
                                Abastecimiento
                            </span>
                        </div>
                        <div class="col-auto d-middle">
                            <div class="bg-df br-4" style="width:18px;height:18px;" />
                            <span class="text-general f-15 mx-3">
                                Retiros
                            </span>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md col-sm col-10 my-xl-0 my-lg-0 my-md-2 my-sm-2 my-1 px-0 mx-2">
                            <div class="date-picker-edit">
                                <el-date-picker
                                v-model="fecha"
                                class="w-100"
                                size="small"
                                format="dd-MM-yyyy"
                                value-format="yyyy-MM-dd"
                                popper-class="date-picker-edit-popper"
                                unlink-panels
                                type="daterange"
                                start-placeholder="Fecha inicio"
                                end-placeholder="Fecha fin"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template slot="adicionales-izquierda">
                <el-table-column
                width="80"
                >
                    <template slot-scope="{ row }">
                        <i class="f-18" :class="`icon-${row.favorito ? 'star orange-star' : 'star-empty'}`" />
                    </template>
                </el-table-column>
                <el-table-column
                width="80"
                class-name="text-center"
                >
                    <template slot-scope="{ row }">
                        <img :src="row.imagen" width="42" height="42" class="br-8 obj-cover" />
                    </template>
                </el-table-column>
                <el-table-column
                label="Producto"
                class-name="text-center text-general f-16"
                >
                    <template slot-scope="{ row }">
                        <p class="text-general2 f-15 cr-pointer">
                            {{ row.detalle_producto }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column
                label="Últ. Costo"
                class-name="text-center text-general f-16"
                >
                    <template slot-scope="{ row }">
                        <p class="text-general2 f-15">
                            {{ separadorNumero(row.ultimo_costo) }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column
                label="Compras al proveedor"
                class-name="text-center text-general f-16"
                width="350"
                >
                    <template slot-scope="{ row }">
                        <div class="row mx-0 align-items-center">
                            <div class="col-6">
                                <p class="text-general2 f-15">
                                    {{ separadorNumero(row.compras) }}
                                </p>
                            </div>
                            <el-tooltip placement="bottom" effect="light">
                                <div slot="content">
                                    <div class="row mx-0 my-2">
                                        <div class="bg-general2 br-5" style="width:18px;height:18px;" />
                                        <span class="mx-2">{{ agregarSeparadoresNumero(row.porcentaje_compras_abastecimiento) }}%</span>
                                        <span class="ml-2">Abastecimiento</span>
                                    </div>
                                    <div class="row mx-0">
                                        <div class="bg-df br-5" style="width:18px;height:18px;" />
                                        <span class="mx-2">{{ agregarSeparadoresNumero(row.porcentaje_compras_retiro) }}%</span>
                                        <span class="ml-2">Retiro</span>
                                    </div>
                                </div>
                                <div class="px-0" style="width:120px;">
                                    <div class="row mx-0 br-5 border" style="height:18px;">
                                        <div class="h-100 bg-general2" :class="`${row.porcentaje_compras_retiro == 0 ? 'br-5' : 'br-l-5'}`" :style="`width:${porcentajesBordes(row.porcentaje_compras_abastecimiento)}%`" />
                                        <div class="h-100 bg-df" :class="`${row.porcentaje_compras_abastecimiento == 0 ? 'br-5' : 'br-r-5'}`" :style="`width:${porcentajesBordes(row.porcentaje_compras_retiro)}%`" />
                                    </div>
                                </div>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                label="Compras a otros proveedores"
                class-name="text-center text-general f-16"
                width="350"
                >
                    <template slot-scope="{ row }">
                        <div class="row mx-0 align-items-center">
                            <div class="col-6">
                                <p class="text-general2 f-15">
                                    {{ separadorNumero(row.compras_otros) }}
                                </p>
                            </div>
                            <el-tooltip placement="bottom" effect="light">
                                <div slot="content">
                                    <div class="row mx-0 my-2">
                                        <div class="bg-general2 br-5" style="width:18px;height:18px;" />
                                        <span class="mx-2">{{ agregarSeparadoresNumero(row.porcentaje_compras_otros_abastecimiento) }}%</span>
                                        <span class="ml-2">Abastecimiento</span>
                                    </div>
                                    <div class="row mx-0">
                                        <div class="bg-df br-5" style="width:18px;height:18px;" />
                                        <span class="mx-2">{{ agregarSeparadoresNumero(row.porcentaje_compras_otros_retiro) }}%</span>
                                        <span class="ml-2">Retiro</span>
                                    </div>
                                </div>
                                <div class="px-0" style="width:120px;">
                                    <div class="row mx-0 br-5 border" style="height:18px;">
                                        <div class="h-100 bg-general2" :class="`${row.porcentaje_compras_otros_retiro == 0 ? 'br-5' : 'br-l-5'}`" :style="`width:${porcentajesBordes(row.porcentaje_compras_otros_abastecimiento)}%`" />
                                        <div class="h-100 bg-df" :class="`${row.porcentaje_compras_otros_abastecimiento == 0 ? 'br-5' : 'br-r-5'}`" :style="`width:${porcentajesBordes(row.porcentaje_compras_otros_retiro)}%`" />
                                    </div>
                                </div>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                label="Stock"
                class-name="text-center text-general f-16"
                width="100"
                >
                    <template slot-scope="{ row }">
                        <p class="f-15">
                            {{ row.stocks }}
                        </p>
                    </template>
                </el-table-column>
            </template>
        </tabla-general>
        <!-- Partials -->
        <modal-ver-producto ref="modalVerProducto" />
    </section>
</template>

<script>
import Proveedor from "~/services/proveedores/proveedores_estadisticas";
import { mapGetters } from 'vuex'
import moment from 'moment';
export default {
    components: {
        modalVerProducto: () => import('../partials/modalVerProducto.vue')
    },
    data(){
        return {
            tableData: [],
            fecha: [moment().subtract(1, 'week').toDate(),new Date()],
            value: ''
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
    },
    watch: {
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
        id_cedis(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
        fecha(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda === null){
                    setTimeout(async() => {
                        await this.productosProveedores()
                    }, 1000);
                }else{
                    await this.productosProveedores()
                }
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        verProducto(row){
            let params = {
                fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                id_moneda: this.id_moneda,
                row
            }
            this.$refs.modalVerProducto.toggle(params);
        },
        async productosProveedores(){
            try {
                let params =  {
                    id: this.$route.params.id_proveedor,
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_moneda: this.id_moneda,
                }
                const {data} = await Proveedor.productosProveedores(params)
                this.tableData = data.productos
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.bg-df{
    background-color: #DFDFDD !important;
}
.orange-star{
    color: #FF9D32;
}
</style>
