var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pt-3"},[_c('tabla-general',{attrs:{"data":_vm.tableData,"activar-seleccion":true,"alto":"calc(100vh - 293px)"},on:{"seleccion":_vm.verProducto}},[_c('template',{slot:"cabecera-izquierda"},[_c('div',{staticClass:"col pr-0 py-2"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-auto f-600 text-general"},[_vm._v(" Productos del proveedor ")]),_c('div',{staticClass:"col-auto px-0 d-middle ml-auto"},[_c('div',{staticClass:"bg-general2 br-4",staticStyle:{"width":"18px","height":"18px"}}),_c('span',{staticClass:"text-general f-15 mx-3"},[_vm._v(" Abastecimiento ")])]),_c('div',{staticClass:"col-auto d-middle"},[_c('div',{staticClass:"bg-df br-4",staticStyle:{"width":"18px","height":"18px"}}),_c('span',{staticClass:"text-general f-15 mx-3"},[_vm._v(" Retiros ")])]),_c('div',{staticClass:"col-xl-3 col-lg-4 col-md col-sm col-10 my-xl-0 my-lg-0 my-md-2 my-sm-2 my-1 px-0 mx-2"},[_c('div',{staticClass:"date-picker-edit"},[_c('el-date-picker',{staticClass:"w-100",attrs:{"size":"small","format":"dd-MM-yyyy","value-format":"yyyy-MM-dd","popper-class":"date-picker-edit-popper","unlink-panels":"","type":"daterange","start-placeholder":"Fecha inicio","end-placeholder":"Fecha fin"},model:{value:(_vm.fecha),callback:function ($$v) {_vm.fecha=$$v},expression:"fecha"}})],1)])])])]),_c('template',{slot:"adicionales-izquierda"},[_c('el-table-column',{attrs:{"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('i',{staticClass:"f-18",class:("icon-" + (row.favorito ? 'star orange-star' : 'star-empty'))})]}}])}),_c('el-table-column',{attrs:{"width":"80","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{staticClass:"br-8 obj-cover",attrs:{"src":row.imagen,"width":"42","height":"42"}})]}}])}),_c('el-table-column',{attrs:{"label":"Producto","class-name":"text-center text-general f-16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-general2 f-15 cr-pointer"},[_vm._v(" "+_vm._s(row.detalle_producto)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Últ. Costo","class-name":"text-center text-general f-16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-general2 f-15"},[_vm._v(" "+_vm._s(_vm.separadorNumero(row.ultimo_costo))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Compras al proveedor","class-name":"text-center text-general f-16","width":"350"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row mx-0 align-items-center"},[_c('div',{staticClass:"col-6"},[_c('p',{staticClass:"text-general2 f-15"},[_vm._v(" "+_vm._s(_vm.separadorNumero(row.compras))+" ")])]),_c('el-tooltip',{attrs:{"placement":"bottom","effect":"light"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"row mx-0 my-2"},[_c('div',{staticClass:"bg-general2 br-5",staticStyle:{"width":"18px","height":"18px"}}),_c('span',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.agregarSeparadoresNumero(row.porcentaje_compras_abastecimiento))+"%")]),_c('span',{staticClass:"ml-2"},[_vm._v("Abastecimiento")])]),_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"bg-df br-5",staticStyle:{"width":"18px","height":"18px"}}),_c('span',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.agregarSeparadoresNumero(row.porcentaje_compras_retiro))+"%")]),_c('span',{staticClass:"ml-2"},[_vm._v("Retiro")])])]),_c('div',{staticClass:"px-0",staticStyle:{"width":"120px"}},[_c('div',{staticClass:"row mx-0 br-5 border",staticStyle:{"height":"18px"}},[_c('div',{staticClass:"h-100 bg-general2",class:("" + (row.porcentaje_compras_retiro == 0 ? 'br-5' : 'br-l-5')),style:(("width:" + (_vm.porcentajesBordes(row.porcentaje_compras_abastecimiento)) + "%"))}),_c('div',{staticClass:"h-100 bg-df",class:("" + (row.porcentaje_compras_abastecimiento == 0 ? 'br-5' : 'br-r-5')),style:(("width:" + (_vm.porcentajesBordes(row.porcentaje_compras_retiro)) + "%"))})])])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Compras a otros proveedores","class-name":"text-center text-general f-16","width":"350"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row mx-0 align-items-center"},[_c('div',{staticClass:"col-6"},[_c('p',{staticClass:"text-general2 f-15"},[_vm._v(" "+_vm._s(_vm.separadorNumero(row.compras_otros))+" ")])]),_c('el-tooltip',{attrs:{"placement":"bottom","effect":"light"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"row mx-0 my-2"},[_c('div',{staticClass:"bg-general2 br-5",staticStyle:{"width":"18px","height":"18px"}}),_c('span',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.agregarSeparadoresNumero(row.porcentaje_compras_otros_abastecimiento))+"%")]),_c('span',{staticClass:"ml-2"},[_vm._v("Abastecimiento")])]),_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"bg-df br-5",staticStyle:{"width":"18px","height":"18px"}}),_c('span',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.agregarSeparadoresNumero(row.porcentaje_compras_otros_retiro))+"%")]),_c('span',{staticClass:"ml-2"},[_vm._v("Retiro")])])]),_c('div',{staticClass:"px-0",staticStyle:{"width":"120px"}},[_c('div',{staticClass:"row mx-0 br-5 border",staticStyle:{"height":"18px"}},[_c('div',{staticClass:"h-100 bg-general2",class:("" + (row.porcentaje_compras_otros_retiro == 0 ? 'br-5' : 'br-l-5')),style:(("width:" + (_vm.porcentajesBordes(row.porcentaje_compras_otros_abastecimiento)) + "%"))}),_c('div',{staticClass:"h-100 bg-df",class:("" + (row.porcentaje_compras_otros_abastecimiento == 0 ? 'br-5' : 'br-r-5')),style:(("width:" + (_vm.porcentajesBordes(row.porcentaje_compras_otros_retiro)) + "%"))})])])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Stock","class-name":"text-center text-general f-16","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"f-15"},[_vm._v(" "+_vm._s(row.stocks)+" ")])]}}])})],1)],2),_c('modal-ver-producto',{ref:"modalVerProducto"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }