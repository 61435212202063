<template>
    <section class="pt-3">
        <div class="row mx-0">
            <div class="col px-0 custom-scroll overflow-auto" style="height:calc(100vh - 199px);">
                <div class="row mx-0 py-4 px-3 bg-white br-8 mr-3">
                    <img :src="proveedor.imagen" width="135" height="135" class="obj-cover br-6 border-gris" />
                    <div class="col">
                        <p class="text-general f-22 f-600">
                            {{ proveedor.nombre }}
                        </p>
                        <div class="row mx-0 text-general f-15">
                            <p class="col pl-0">
                                Nit. {{ proveedor.nit || 'No registra' }}
                            </p>
                            <p class="col text-right">
                                Creación: {{ formatearFecha(proveedor.created_at) }}
                            </p>
                        </div>
                        <div class="row mx-0 py-2" />
                        <p class="text-general f-15 mt-3 f-500">
                            {{ proveedor.pais }}, {{ proveedor.ciudad }}
                        </p>
                        <p class="text-general f-15">
                            {{ proveedor.direccion }}
                        </p>
                    </div>
                </div>
                <div class="row mx-0 px-3 bg-white br-8 mr-3 py-3 mt-3">
                    <p class="col-12 text-general f-15 f-500">
                        Observaciones
                    </p>
                    <p class="col-12 text-general f-15 my-2">
                        {{ proveedor.observaciones ? proveedor.observaciones : 'No registra' }}
                    </p>
                </div>
                <div class="row mx-0 px-3 bg-white mt-3 py-3 br-8 mr-3">
                    <div class="col-12 d-middle mt-2">
                        <span class="text-general f-600">
                            Contactos del proveedor
                        </span>
                        <el-tooltip v-if="$can('botones_proveedores_gestionar_contactos')" placement="bottom" content="Añadir contacto" effect="light">
                            <div class="btn-red p-1 ml-3 br-8 text-white cr-pointer" @click="anadirContacto(1)">
                                <i class="icon-plus f-18" />
                            </div>
                        </el-tooltip>
                    </div>
                    <div class="col-12 px-2">
                        <div v-for="(contacto,co) in contactos" :key="co" class="row mx-0 pr-0pl-1 align-items-center my-2">
                            <div class="col-auto pr-2 pl-0">
                                <i class="cr-pointer f-18" :class="`icon-${contacto.importante ? 'star orange-star' : 'star-empty'}`" @click="favoritoContacto(contacto)" />
                            </div>
                            <div class="col border br-8 py-2">
                                <div class="row mx-0 align-items-center">
                                    <el-tooltip placement="bottom" :content="contacto.observaciones || 'Sin observaciones'" effect="light">
                                        <div class="col pr-0 pl-2 text-general">
                                            <div class="row mx-0">
                                                <p class="col-12 f-15 f-500 px-0 tres-puntos">
                                                    {{ contacto.nombre }}
                                                </p>
                                            </div>
                                            <div class="row mx-0 mt-2">
                                                <div class="col-12 f-15 px-0 tres-puntos">
                                                    {{ contacto.cargo }}
                                                </div>
                                            </div>
                                        </div>
                                    </el-tooltip>
                                    <div class="col">
                                        <div class="row mx-0 align-items-center">
                                            <i class="icon-phone text-general f-18" />
                                            <p class="col text-general f-15">
                                                {{ contacto.telefono }}
                                            </p>
                                        </div>
                                        <div class="row mx-0 align-items-center">
                                            <i class="icon-mail text-general f-18" />
                                            <p class="col text-general f-15">
                                                {{ contacto.correo || 'sin correo' }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-auto d-flex flex-column">
                                        <i v-if="$can('botones_proveedores_gestionar_contactos')" class="icon-pencil-outline text-general f-20 cr-pointer" @click="editarContacto(contacto)" />
                                        <i v-if="$can('botones_proveedores_gestionar_contactos')" class="icon-delete-outline text-general f-20 cr-pointer" @click="eliminarContacto(contacto.id)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-5 bg-white br-8 pt-4 custom-scroll overflow-auto" style="height:calc(100vh - 199px);">
                <div class="row mx-0 px-4 align-items-center mb-4">
                    <div class="col-auto pl-0 text-general f-15 f-600">
                        Cedis a los cuales vende este proveedor
                    </div>
                    <div class="col-auto px-0 ml-auto">
                        <el-tooltip v-if="$can('botones_proveedores_gestionar_cedis')" placement="bottom" content="Añadir Cedis" effect="light">
                            <div class="btn-red br-8 shadow cr-pointer" @click="anadirCedisAsociados()">
                                <i class="icon-pencil-outline f-20 text-white" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
                <div v-for="(cedi,ce) in cedis" :key="ce" class="row mx-0 px-4 mb-3">
                    <div class="col-auto px-0">
                        <img :src="cedi.imagen" width="77" height="77" class="obj-cover br-8" />
                        <el-tooltip placement="bottom" effect="light">
                            <div slot="content">
                                <p class="text-general2 text-center f-600">
                                    Compras en último mes
                                </p>
                                <div class="row mx-0 my-2">
                                    <div class="bg-general2 br-5" style="width:18px;height:18px;" />
                                    <span class="mx-2">{{ cedi.porcentaje_compras_abastecimiento }}%</span>
                                    <span class="ml-2">Abastecimiento</span>
                                </div>
                                <div class="row mx-0">
                                    <div class="bg-df br-5" style="width:18px;height:18px;" />
                                    <span class="mx-2">{{ cedi.porcentaje_compras_retiro }}%</span>
                                    <span class="ml-2">Retiro</span>
                                </div>
                            </div>
                            <div class="row mx-0 br-5 mt-2 border" style="height:18px;">
                                <div class="h-100 bg-general2" :class="`${cedi.porcentaje_compras_retiro == 0 ? 'br-5' : 'br-l-5'}`" :style="`width:${porcentajesBordes(cedi.porcentaje_compras_abastecimiento)}%`" />
                                <div class="h-100 bg-df" :class="`${cedi.porcentaje_compras_abastecimiento == 0 ? 'br-5' : 'br-r-5'}`" :style="`width:${porcentajesBordes(cedi.porcentaje_compras_retiro)}%`" />
                            </div>
                        </el-tooltip>
                    </div>
                    <div class="col">
                        <p class="text-general f-15">
                            {{ cedi.nombre }}
                        </p>
                        <div class="row mx-0 align-items-center">
                            <p class="col-auto px-0 text-general">
                                Última compra:
                            </p>
                            <p class="col-5 text-general2">
                                {{ formatearFecha(cedi.ultima_compra) }}
                            </p>
                            <i v-if="$can('botones_proveedores_gestionar_cedis')" class="icon-delete-outline f-20 cr-pointer text-general" @click="deleteCedis(cedi.id)" />
                        </div>
                        <div class="row mx-0">
                            <p class="col-auto px-0 text-general">
                                Compras último mes:
                            </p>
                            <p class="col-auto text-general2">
                                {{ separadorNumero(cedi.compras) }}
                            </p>
                        </div>
                        <div class="row mx-0">
                            <p class="col-auto px-0 text-general">
                                Productos favoritos:
                            </p>
                            <p class="col-auto text-general2">
                                {{ agregarSeparadoresNumero(cedi.favoritos) }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-anadir-contacto ref="modalAnadirContacto" @agregar="agregarContacto" />
        <modal-editar-contacto ref="modalEditarContacto" @editar="editContacto" />
        <modal-cedis-asociados ref="modalCedisAsociados" @listar="getProveedorContactosCedis" />
        <modal-eliminar ref="modalEliminarContacto" titulo="Eliminar Contacto" mensaje="¿Desea eliminar el contacto de este proveedor?" @eliminar="deleteContacto" />
    </section>
</template>

<script>
import Proveedor from "~/services/proveedores/proveedores";
import { mapGetters } from 'vuex'
export default {
    components: {
        modalAnadirContacto: () => import('../partials/modalAñadirContacto'),
        modalEditarContacto: () => import('../partials/modalEditarContacto'),
        modalCedisAsociados: () => import('../partials/modalCedisAsociados')
    },
    data(){
        return{
            url_image: 'https://pintuco.com.co/wp-content/uploads/2020/01/pintar-fachadas-tienda-d1-pintuco-imgppal.jpg',
            modalContacto: 1,
            url_cedis: 'http://interlake.com.mx/blog/wp-content/uploads/2019/04/bodega-portada.jpg',
            contactos: [],
            cedis: [],
            idCedis: null,
            idContacto: null,
        }
    },
    computed: {
        ...mapGetters({
            proveedor:'proveedores/proveedores/proveedor',
            id_cedis:'cedis/id_cedis',
            cedisLista:'cedis/cedis',
            id_moneda:'cedis/id_moneda',
        }),
    },
    watch: {
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
        id_cedis(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda === null){
                    setTimeout(async() => {
                        await this.getProveedorContactosCedis()
                    }, 1000);
                }else{
                    await this.getProveedorContactosCedis()
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async getProveedorContactosCedis(){
            try {
                let params =  {
                    id: this.$route.params.id_proveedor,
                    id_cedis: this.id_cedis,
                    id_moneda: this.id_moneda,
                }
                const {data} = await Proveedor.getProveedorContactosCedis(params)

                this.contactos = data.contactos
                this.cedis = data.cedis
            } catch (e){
                this.error_catch(e)
            }
        },
        anadirContacto(){
            this.$refs.modalAnadirContacto.toggle();
        },
        editarContacto(model){
            this.$refs.modalEditarContacto.toggle(model);
        },
        agregarContacto(model){
            this.contactos.push(model)
        },
        editContacto(model){
            let index = this.contactos.findIndex(el => el.id == model.id)
            this.$set(this.contactos, index, model)
        },
        eliminarContacto(id){
            this.idContacto = id
            this.$refs.modalEliminarContacto.toggle();
        },
        async deleteContacto(){
            try {
                const {data} = await Proveedor.deleteContacto(this.idContacto)
                this.notificacion('', data.mensaje, 'success')
                let index = this.contactos.findIndex(el => el.id == this.idContacto)
                this.contactos.splice(index, 1)
                this.$refs.modalEliminarContacto.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        async deleteCedis(id){
            try {
                const {data} = await Proveedor.deleteCedis(this.$route.params.id_proveedor, id)
                this.notificacion('', data.mensaje, 'success')
                let index = this.cedis.findIndex(el => el.id == id)
                this.cedis.splice(index, 1)
            } catch (e){
                this.error_catch(e)
            }
        },
        async favoritoContacto(contacto){
            try {
                if (!this.$can('botones_proveedores_gestionar_contactos')) return
                const {data} = await Proveedor.favoritoContacto(contacto.id)
                contacto.importante = !contacto.importante
                this.notificacion('', data.mensaje, 'success')

            } catch (e){
                this.error_catch(e)
            }
        },
        anadirCedisAsociados(){
            let newLista = this.cedisLista.reduce((acc, el) => {
                if(el.id != 0){
                    el.checked = this.cedis.some(ele => ele.id_cedis == el.id)
                    acc.push(el)
                }
                return acc
            }, [])
            console.log({newLista});
            this.$refs.modalCedisAsociados.toggle(newLista);
        }
    }
}
</script>
<style lang="scss" scoped>
.bg-df{
    background-color: #DFDFDD !important;
}
.orange-star{
    color: #FF9D32;
}
</style>
