import axios from "axios";

const API = "proveedores-estadisticas";

const proveedores = {
    productosProveedores: (params = {}) => axios(`${API}/proveedor-productos/${params.id}`, { params }),
    modalProductoProveedor: (params = {}) => axios(`${API}/modal-proveedor-productos/${params.id}`, { params }),
    proveedorSemanas: (params = {}) => axios(`${API}/proveedor-semanas/${params.id}`, { params }),
    proveedorSemanasInforme: (params = {}) => axios(`${API}/proveedor-semanas-informe/${params.id}`, { params }),
    proveedoresEstadisticas: (params = {}) => axios(`${API}/dashboard`, { params }),
    modalProductosProveedor: (params = {}) => axios(`${API}/modal-productos-proveedor`, { params }),
};

export default proveedores;
