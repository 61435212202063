import axios from "axios";

const API = "proveedores";

const proveedores = {
    listarProveedores: (params = {}) => axios(`${API}/proveedor`, { params }),
    getProveedor: (params = {}) => axios(`${API}/proveedor/${params.id}`, { params }),
    getProveedorContactosCedis: (params = {}) => axios(`${API}/proveedor-contactos-cedis/${params.id}`, { params }),
    crearProveedor: (params) => axios.post(`${API}/proveedor`, params),
    editarProveedor: (params) => axios.put(`${API}/proveedor/${params.id}`, params),
    cambiarEstadoProveedor: (id) => axios.put(`${API}/proveedor-cambiar-estado/${id}`),
    getComentariosProveedor: (idProveedor) => axios.get(`${API}/${idProveedor}/comentarios`),
    crearComentarioProveedor: (params) => axios.post(`${API}/crear-comentario`,params),
    deleteProveedor: (id) => axios.delete(`${API}/proveedor/${id}`),

    //contactos
    crearContacto: (params) => axios.post(`${API}/proveedor-contacto`, params),
    editarContacto: (params) => axios.put(`${API}/proveedor-contacto/${params.id}`, params),
    favoritoContacto: (id) => axios.put(`${API}/proveedor-favorito-contacto/${id}`),
    deleteContacto: (id) => axios.delete(`${API}/proveedor-contacto/${id}`),

    //cedis
    crearCedis: (params) => axios.post(`${API}/proveedor-cedis`, params),
    deleteCedis: (idProveedor, idCedis) => axios.delete(`${API}/proveedor-cedis/${idProveedor}/${idCedis}`),

};

export default proveedores;
