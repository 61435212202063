<template>
    <section class="custom-scroll overflow-auto" style="height:calc(100vh - 86px)">
        <div class="row mx-0">
            <i class="icon-back text-general f-22 mt-1 cr-pointer" @click="$router.back()" />
            <div class="col px-2">
                <div class="row mx-0 title-category mb-4">
                    <p class="col px-0 f-28 text-general f-500 mt-2">
                        Misiones de "Septiembre"
                    </p>
                </div>
                <div class="row mx-0">
                    <div class="col-12 pl-0">
                        <div class="row mx-0 mb-4">
                            <div class="col-auto px-0">
                                <img src="https://cdn.pixabay.com/photo/2016/08/03/09/03/universe-1566159__340.jpg" class="br-8 obj-cover img-evento" width="360" height="120" />
                            </div>
                            <div class="col px-xl-3 px-lg-3 px-md-0 px-sm-0 px-0">
                                <p class="f-600 f-18 mt-2 text-general f-600">
                                    Con cada misión lograda obtendrás mejores <br /> recompensas
                                </p>
                                <p class="f-18 mt-2 text-general f-600">
                                    ¡Apresúrate y hazte con las gemas y monedas!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pl-0">
                        <div class="row mx-0">
                            <div class="col pl-0">
                                <div class="d-middle mb-2">
                                    <img src="/img/gaming/i_bag_money.png" width="40" height="40" />
                                    <p class="col-auto px-2 text-general">
                                        <span class="f-600">Compras</span> (Dinero)
                                    </p>
                                </div>
                                <div v-for="(data, d1) in 3" :key="`compras-${d1}`" class="row mx-0 mb-3">
                                    <div class="col-12 px-0">
                                        <card-mision />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="d-middle mb-2">
                                    <img src="/img/gaming/i_checklist.png" width="40" height="40" />
                                    <p class="col-auto px-2 text-general">
                                        <span class="f-600">Compras</span> (Cantidad de pedidos)
                                    </p>
                                </div>
                                <div v-for="(data, d) in 3" :key="`compras-ped-${d}`" class="row mx-0 mb-3">
                                    <div class="col-12 px-0">
                                        <card-mision />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="d-middle mb-2">
                                    <img src="/img/gaming/i_dinero.png" width="40" height="40" />
                                    <p class="col-auto px-2 text-general">
                                        <span class="f-600">Compras</span> (Cantidad de pedidos)
                                    </p>
                                </div>
                                <div v-for="(data, d) in 3" :key="`compras-ped-${d}`" class="row mx-0 mb-3">
                                    <div class="col-12 px-0">
                                        <card-mision />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="d-middle mb-2">
                                    <img src="/img/gaming/i_ventas_cantidad.png" width="40" height="40" />
                                    <p class="col-auto px-2 text-general">
                                        <span class="f-600">Compras</span> (Cantidad de pedidos)
                                    </p>
                                </div>
                                <div v-for="(data, d) in 3" :key="`compras-ped-${d}`" class="row mx-0 mb-3">
                                    <div class="col-12 px-0">
                                        <card-mision />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    components: {
        cardMision: () => import('./components/cardMision')
    },
    data(){
        return {

        }
    }
}
</script>
<style scoped lang="scss">
.card-compra{
    width: 285px;
    min-height: 72px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
}

@media (min-width: 300px) and (max-width: 815px) {
    .img-evento{
        width: 100% !important;
    }
}

</style>